#hit-count {
  margin-bottom: 30px;
}

.biblio-letter {
  font-size: 30pt;
  color: #8a3122;
  margin-right: 60px;
}

.citation {
  margin-bottom: 40px;

  em {
    background-color: lemonchiffon;
    text-decoration: underline;
    font-style: normal;
    font-weight: bold;
  }
}

.letter-group {
  display: flex;
}
