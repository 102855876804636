#data-page {
  height: 50vh;
  width: 100%;
  display: flex;
  background-position: 0%;
}

#data-subpage-menu {
  width: 30%;
  // border: 1px solid pink;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

  a, a:visited {
    text-decoration: none;
    color: #777;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selected {
    background-color: #d3d3d3;
  }
}

#data-subpage-content {
  width: 70%;
  // border: 1px solid green;
  background-color: rgba(255,255,255,0.45);
  padding: 0 7vw;
  overflow: scroll;

  h2 {
    box-sizing: border-box;
    height: 60px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
  }

  p {
    margin-top: 0;
  }
}

.data-subpage {
  height: 40px;
  width: 80%;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid black;
  font-size: 1.25em;
  background-color: #fdfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 4px 10px 15px -9px rgba(122,120,122,1);
  -moz-box-shadow: 4px 10px 15px -9px rgba(122,120,122,1);
  box-shadow: 4px 10px 15px -9px rgba(122,120,122,1);

  &:hover {
    background-color: rgba(211, 211, 211, 0.5);
    cursor: pointer;
  }
}


@media (max-width: 800px) {
  #data-page {
    flex-direction: column;
  }

  #data-subpage-menu {
    flex-direction: row;
    width: 90%;
    align-self: center;
  }

  .data-subpage {
    margin-right: 10px;
    font-size: 1em;
  }

}
