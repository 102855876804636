ul.tabcontrol.tabs {
  margin: 0 0 10px 0;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: center;
}

ul.tabcontrol.tabs li.tab {
  padding: 15px;
  cursor: pointer;
  list-style-type: none;
  border: 1px solid lightgrey;
  border-bottom: none;
  box-sizing: border-box;
  height: 49px;
  background: white;
  margin-right: 2px;
  font-size: 1.5em;
  line-height: 100% !important;
  border-radius: 2px 2px 0 0;
}

ul.tabcontrol.tabs li.tabcontrol.tab.active {
  height: 53px;
  font-weight: bold !important;
  margin-top: -3px;
  border-top: 3px solid #8a3122;
}

ul.tabcontrol.tabs li.tabcontrol.tab:first-child {
  border-left: 1px solid lightgrey;
}

div.tabcontrol.body {
  padding: 0;
}

div.tabcontrol.body.fullborder {
  border: 1px solid lightgrey;
  border-top: none;
  padding: 10px;
}

div.tabcontrol.body p:last-child, div.tabcontrol.body ul:last-child {
  margin-bottom: 0;
}

div.hidden-tab {
  display: none;
}
