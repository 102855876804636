#navbar {
	height: 50px;
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #777;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 180px;
		background-image: url('/assets/img/plan-de-cassini.jpeg');
		background-position: 50% 20%;
		background-size: 150%;
		z-index: -1;
	}

	ul {
		list-style-type: none;
		margin: 0 4em;
		padding: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		height: 100%;
		text-transform: uppercase;

		li {
			padding: 0 20px;
			display: flex;
			align-items: center;
			background-color: whitesmoke;

			&:hover {
				background-color: rgba(138,49,34,0.7);
				cursor: pointer;

				a, a:visited {
					color: #f7efc1;
				}
			}

			a, a:visited {
				text-decoration: none;
				color: #777;
			}

		}
	}

	.navbar-selected {
		background-color: #8a3122;
		color: #f7efc1;

		&:hover {
			background-color: #8a3122;
		}

		a, a:visited {
			color: #f7efc1;
		}
	}

}

#page-selection {
	height: 50px;
	background-color: #8a3122;
	color: #f7efc1;
	font-size: 1.5em;
	display: flex;
	align-items: center;
	padding: 1em 3.75em;
	font-family: 'Lato', serif;
	font-weight: normal;
	box-sizing: border-box;
	margin-bottom: 1em;
	margin-left: -20px;
	margin-right: -30px;
	-webkit-box-shadow: 0px 13px 15px -5px #969696;
	box-shadow: 0px 13px 15px -5px #969696;
}

// @media (max-width: 800px) {
//
// }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


// #f7b083
// #74c8a3
// #f7efc1
