footer {
  $gutter:0em;
  $base-spacing: 0em !default;
  $action-color: #477DCA !default;
  $medium-screen: 40em !default;
  $large-screen: 53.75em !default;
  font-size: 0.9em;
  $footer-color: rgba(0,0,0,0.8);
  $footer-link-color: transparentize($footer-color, 0.6);
  $footer-disclaimer-color: transparentize($footer-color, 0.6);
  overflow: hidden;
  background-image: linear-gradient(to top, #fdfbfb 0%, #ebedee 100%);
  display: block;
  clear: both;
  position: relative;
  bottom: 10px;
  width: 90%;
  margin: 20px auto;
  -webkit-box-shadow: 2px 5px 12px 6px rgba(0,0,0,0.2);
  box-shadow: 2px 5px 12px 6px rgba(0,0,0,0.2);

  .grid {
    display: flex;
    align-items: space-evenly;
    justify-content: center;
  }

  .footer-logo {
    text-align: center;

    img {
      max-height: 100px;
      max-width: 200px;
    }
  }

  .partners-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 2em;
  }
}
