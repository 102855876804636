body {
	color: #777;
	font-family: 'Lato','Helvetica Neue',Arial,Helvetica,sans-serif;
}

html {
	font-size: 100%;
	font-family: 'Crimson Pro', serif;
}



p {
	margin-top: 0.3em;
	margin-bottom: 1.5em !important;
	color: rgba(0, 0, 0, 0.7) !important;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;

	// font-size: calc(1.5em + 0.25 * (100vw - 160em) / 80) !important;
	// font-size: -webkit-calc(150% + 0.25 * (100vw - 16000%) / 80) !important;
	font-size: 1em;
	line-height: 1.5em;
	// line-height: calc(1.7em + 0.25 * (100vw - 160em) / 80) !important;
	// line-height: -webkit-calc(170% + 0.25 * (100vw - 16000%) / 80) !important;
}

.content-subhead {
	font-weight: 500;
	font-style: italic;
	margin-top: 0.3em !important;
	margin-bottom: 0.5em !important;

	font-size: calc(1.9em + 0.25 * (100vw - 160em) / 80) !important;
	font-size: -webkit-calc(190% + 0.25 * (100vw - 16000%) / 80) !important;
	line-height: calc(1.9em + 0.25 * (100vw - 160em) / 80) !important;
	line-height: -webkit-calc(190% + 0.25 * (100vw - 16000%) / 80) !important;
}

.grid-img-responsive {
	max-width: 100%;
	height: auto;
}

#layout,
#menu,
.menu-link,
.main-content {
	width: 90%;
	margin: auto;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

#layout {
	padding: 0.5em;

  .fr, .en {
    text-transform: capitalize;
  }

	&.active {
		#menu {
			left: 33vw;
			width: 33vw;
		}

		.menu-link {
			left: 0vw;
		}
	}
}

#header {
	margin: 0;
	color: #777;
	text-align: center;
	position: relative;

	.grid {
	  display: flex;
	  position: absolute;
	  right: 0.75em;
	  top: 0.75em;

		a {
			display: inline-block;
			top: 0;
			font-size: 1.2em;
			height: 1em;
			padding: 0.5em;
			text-decoration: none;
			text-transform: capitalize;
			background-color: rgba(245,245,245,0.4);
			border-radius: 50%;

			&:hover {
				transform: translateY(-3px);
				transition-duration: 0.2s;
			}
		}
	}

	img {
		background-color: rgba(245,245,245,0.4);
		padding: 0 0.75em;
		border-radius: 23%;
	}

	h1 {
		font-size: 2.5em;
		font-family: cursive;
	}

	h2 {
		margin: 0.2em 0;
		font-size: 3em;
		font-weight: 300;
	}

	h3 {
		font-weight: 300;
		color: #777;
		padding: 0;
		margin-top: 0;
	}

}

section {
	// background-image: url('/assets/img/plan-de-verniquet-portrait.png');
	background-position: -20%;
	background-size: 120% auto;
	background-repeat: no-repeat;
	z-index: 1;
	overflow: visible;

	div.header {
		img {
			width: calc(50vw * 0.25);
			padding: 0 0 1.5em;
			min-width: 300px;
		}
	}
	.main-content {
		background-color: white;
		  padding: 1em 7vw;
		  padding-bottom: 10%;

		h2 {
			margin: 1em 0;
			font-size: 3em;
			font-weight: 300;
			text-align: center;
		}

		#embedded-map {
			width: 100%;
			height: 100vh;
		}

		svg.section-logo {
			width: calc(50vw * 0.25);
		}
	}
}

#section_1 {
	padding: 0 !important;
	background-image: none;

	.main-content {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 100%;

		.map-text {
			width: 20%;
			margin-right: 1em;
			margin-top: 51px;
		}

		.responsive-iframe {
			width: 80%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

#map {
	width: 100%;
}

.modal a.close-modal {
  z-index: 1;
}

@media (min-width: 250em) {
	//.header, .content {
	.header {
		padding-left: 2em;
		padding-right: 2em;
	}

	#layout {
		padding-left: 0px;
		// left: 0;
	}

	#menu {
		//left: 33vw;

		left: 0px;
	}

	.menu-link {
		position: fixed;
		left: 33vw;
		display: none;
	}
}

#logo {
	height: 100px;
	margin-bottom: 10px;
}

@media (max-width: 800px) {
	#section_1 {

		.main-content {

			.map-text {
				width: 100%;
				// padding: 10px;
			}

			.responsive-iframe {
				width: 100%;
			}
		}
	}
}
